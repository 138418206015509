/* modules imports */
import { useRef } from "react";

/* types imports */
import type { FC } from "react";
import type { GetManyArtistsResponse } from "services/artist";
import type { UserEntity } from "services/user";
import type { SnackbarRefProps } from "components/Snackbar";

/* project files imports */
import BoxWrapper from "../BoxWrapper";
import Card from "../Card";
import { NB_ARTISTS_PER_GAME } from "constants/game";
import artistService from "services/artist";
import Snackbar, { SnackbarType } from "components/Snackbar";
import { ButtonSize, ButtonVariant } from "components/Button";

/* styles imports */
import { BoldText, ButtonText, ButtonWrapper, CardWrapper, Main, Text } from "./index.styles";

type PlayViewProps = {
  setArtists: (artist: GetManyArtistsResponse | null) => void;
  user: UserEntity | null;
};

const PlayView:FC<PlayViewProps> = ({ setArtists, user }) => {
  const ref = useRef<SnackbarRefProps>(null);

  const handlePlayButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    artistService.getManyArtists()
      .then(setArtists)
      .catch((err) => ref.current?.open(err?.response?.data?.message));
  };

  return (
    <Main>
      <BoxWrapper alignItems="flex-end" height={48}>
        <Text>
          Hi <BoldText>{user?.name}</BoldText>!
          You have played <BoldText>{user?.totalGamesPlayed}</BoldText> games so far
        </Text>
      </BoxWrapper>
      <CardWrapper>
        {[...Array(NB_ARTISTS_PER_GAME)].map((_, index) => (
          <Card key={index} cardIndex={index} />
        ))}
      </CardWrapper>
      <BoxWrapper alignItems="flex-start" height={48}>
        <ButtonWrapper
          onClick={handlePlayButton}
          size={ButtonSize.MEDIUM}
          variant={ButtonVariant.OUTLINED}
        >
          <ButtonText>
            Play
          </ButtonText>
        </ButtonWrapper>
      </BoxWrapper>

      <Snackbar ref={ref} type={SnackbarType.ERROR} />
    </Main>
  );
};

export default PlayView;
