/* modules imports */
import { useState } from "react";

/* types imports */
import type { FC } from "react";
import type { GetManyArtistsResponse } from "services/artist";
import type { GameEntity } from "services/game";
import type { Actions } from "constants/game";

/* project files imports */
import PlayView from "./PlayView";
import SubmitView from "./SubmitView";
import PlayAgainView from "./PlayAgainView";
import MissingUserTopArtists from "./MissingUserTopArtists";
import useAuth from "hooks/useAuth";
import Navigation from "components/Navigation";
import notesLeft from "assets/lotties/notes-left.json";
import notesRight from "assets/lotties/notes-right.json";

/* styles imports */
import { Content, StyledLottie } from "./index.styles";

const PlayPage:FC = () => {
  const { user, setUser } = useAuth();
  const [artists, setArtists] = useState<GetManyArtistsResponse | null>(null);
  const [choices, setChoices] = useState<(Actions | null)[]>([null, null, null]);
  const [gameResult, setGameResult] = useState<GameEntity | null>(null);

  return (
    <>
      <Navigation />
      <Content>
        <StyledLottie
          animationData={notesLeft}
          loop
          play
          position="left"
        />
        {user?.canPlayGame
          ? gameResult
            ? <PlayAgainView
              gameResult={gameResult}
              setArtists={setArtists}
              setChoices={setChoices}
              setGameResult={setGameResult}
            />
            : artists
              ? <SubmitView
                artists={artists}
                choices={choices}
                setChoices={setChoices}
                setGameResult={setGameResult}
              />
              : <PlayView
                setArtists={setArtists}
                user={user}
              />
          : <MissingUserTopArtists
            setUser={setUser}
            user={user}
          />
        }
        <StyledLottie
          animationData={notesRight}
          loop
          play
          position="right"
        />
      </Content>
    </>
  );
};

export default PlayPage;
